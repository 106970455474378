.img-preview > img {
  object-fit: cover;
}

.Stream {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);

  transition-property: box-shadow, transform;
  will-change: box-shadow, transform;
}

.Stream:hover {
  box-shadow: 0 0.75em 1.5em -0.125em rgba(10, 10, 10, 0.1),
    0 0px 0 1px rgba(10, 10, 10, 0.02);

  transform: scale(1.02) translateY(-4px);
}

.Stream:active {
  box-shadow: 0 0.25em 0.5em -0.125em rgba(10, 10, 10, 0.2),
    0 0px 0 1px rgba(10, 10, 10, 0.02);

  transform: scale(0.98) translateY(-2px);
}
