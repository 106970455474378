$size-7: 0.85em;
$body-background-color: hsl(0, 0%, 96%);

@import "bulma/bulma.sass";

.hr {
  background-color: #5a5353;
  content: "";
  height: 1px;
  transform-origin: center;
  width: 80%;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.header {
  padding-bottom: 0.25rem;
}

// Animations
// -----------

@keyframes fillUp {
  from {
    transform: scaleX(0);
  }
  to {
    transform: scaleX(1);
  }
}

.hr {
  animation-name: fillUp;

  animation-duration: 2s;
  animation-timing-function: cubic-bezier(0, 0.5, 0, 1);
  animation-fill-mode: both;
}
